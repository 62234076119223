import http from '../http';

export interface ClientInfo {
	client: 'win | osx | ios | android | linux | web';
	deviceId: string;
	deviceName?: string;
	version?: string;
}

export interface LoginReq {
	smsCode: string;
	smsToken: string;
	client: ClientInfo;
}

export interface LoginResp {
		id:            string;
		zyCode:        string;
		mail:          string;
		phone:         string;
		clientId:      string;
		username:      string;
		nickname:      string;
		emptyPass:     boolean;
		accessToken:   string;
		accessExpire:  number;
		accessAfter:   number;
		refreshToken:  string;
    refreshExpire: number;
		refreshAfter:  number;
		avatar:        string;
}
	
export interface SmsCodeResp {
	smsToken: string;
	expire: number;
}

export const smsCodeRegisterLogin = (smsCode: string, smsToken: string) =>
	http.post<LoginResp>(`/v1/user/smsCodeRegLogin`, {
		smsCode,
		smsToken,
		client: {
			client: 'web',
			deviceId: 'website',
			deviceName: 'website',
			version: 'web_0.0.1',
		}
	}, true, true);

export const smsCode = (mobile: string) =>
	http.post<SmsCodeResp>(`/v1/user/smsCode`, { mobile }, true, true);

export const refreshToken = (refreshToken: string) =>
	http.post<LoginResp>(`/v1/user/refreshToken`, { refreshToken }, true, true);

const ZhiYaUserStorageKey = 'zhyaUser';

export const saveLoginRespToLocalStorage = (loginResp: LoginResp) => {
	localStorage.setItem(ZhiYaUserStorageKey, JSON.stringify(loginResp));
}

export const getLoginRespFromLocalStorage = () => {
	let loginResp = localStorage.getItem('loginResp');
	if (loginResp) {
	  return JSON.parse(loginResp) as LoginResp;
	}
}