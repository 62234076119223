import { Suspense, useEffect, useState } from 'react';
import './App.css';
import { ConfigProvider } from 'antd';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLanguageLocale, locales, defaultLanguage } from './i18n'
import intl from 'react-intl-universal';
import { saveLoginResp } from './slices/loginUser';
import routes from './routes';
import { getLanguage } from './selectors';
import { languageApi, userApi } from './api';
import { changeLanguage } from './slices/language';
import { matchMediaChange } from './slices/matchMedia';
import { Locale } from './i18n';

const App: React.FC = () => {
	const { language } = useSelector(getLanguage);
	const [locale, setLocale] = useState<Locale>(getLanguageLocale(language));
	
	const matchMediaChanged = (e: MediaQueryListEvent) => {
		dispatch(matchMediaChange(e.matches ? 'mobile' : 'desktop'));
	}

	const setLocaleByLanguage = (lang: string) => {
		let locale = getLanguageLocale(lang);
		dayjs.locale(locale.dayjs);
		intl.init({
			currentLocale: locale.locale,
			locales,
		});
		setLocale(locale);
	}

	const dispatch = useDispatch()
  useEffect(() => {
		languageApi.getLanguage(defaultLanguage).then(res => {
			setLocaleByLanguage(res.data || defaultLanguage)
      dispatch(changeLanguage(res.data || defaultLanguage))
		})
		const mediaQuery = window.matchMedia("(max-width: 900px)");
		dispatch(matchMediaChange(mediaQuery.matches ? 'mobile' : 'desktop'));

		mediaQuery.addEventListener("change", matchMediaChanged);

		return () => {
		  mediaQuery.removeEventListener("change", matchMediaChanged)
		}

  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	let loginResp = userApi.getLoginRespFromLocalStorage()
	let currentTime = new Date().getTime()
	if (loginResp && loginResp.id && loginResp.accessAfter > currentTime - 1000*60*30) {
	  dispatch(saveLoginResp(loginResp));
	}

	const element = useRoutes(routes);
  return (
		<ConfigProvider locale={locale.antd}>
      <Suspense>{element}</Suspense>
    </ConfigProvider>
  );
}

export default App;
