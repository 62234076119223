import zhCN from './zh_CN.json';
import zhTW from './zh_TW.json';
import enUS from './en_US.json';
import antdZhCN from 'antd/es/locale/zh_CN';
import antdZhTW from 'antd/es/locale/zh_TW';
import antdEnUS from 'antd/es/locale/en_US';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/en';

export interface Locale {
    message: any,
    antd: any,
    dayjs: 'zh-cn' | 'en' | 'zh-tw'
    locale: 'zh_CN' | 'en_US' | 'zh_TW'
}

export const locales = {
    'zh_CN': zhCN,
    'zh_TW': zhTW,
    'en_US': enUS,
}

export const defaultLanguage = 'zh_CN'

export function getLanguageLocale(language: string): Locale {
    if (language === 'zh_TW') {
        return {
            message: zhTW,
            antd: antdZhTW,
            dayjs: 'zh-tw',
            locale: 'zh_TW',
        }
    } else if (language === 'en_US') {
        return {
            message: enUS,
            antd: antdEnUS,
            dayjs: 'en',
            locale: 'en_US'
        }
    }
    return {
        message: zhCN,
        antd: antdZhCN,
        dayjs: 'zh-cn',
        locale: 'zh_CN'
    }
}