/* eslint-disable no-unused-vars */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import intl from 'react-intl-universal';
import { message, Modal } from 'antd';
import { BaseResponse } from './types';

/** Loadding */

let loading: { close: () => void } | undefined;
let timeoutClose: NodeJS.Timeout | undefined;
const requestingList: Array<string> = [];

function createLoading(url: string) {
  requestingList.push(url);
  let timeoutShow: NodeJS.Timeout | undefined;
  if (!loading) {
    timeoutShow = setTimeout(() => {
      if (requestingList.length > 0 && !loading) {
        message.loading({
          type: 'loading',
          content: intl.get('loading'), // '请求中，请稍候...'
          duration: 0,
        });
      }
    }, 300);
  }
  if (timeoutClose) {
    clearTimeout(timeoutClose);
    timeoutClose = undefined;
  }
  return () => {
    if (timeoutShow) {
      clearTimeout(timeoutShow);
      timeoutShow = undefined;
    }
    if (requestingList.indexOf(url) > -1) {
      requestingList.splice(requestingList.indexOf(url), 1);
    }
    if (requestingList.length === 0) {
      timeoutClose = setTimeout(() => {
        if (requestingList.length === 0 && loading) {
          message.destroy();
          loading = undefined;
        }
      }, 30);
    }
  };
}

/** error */

let errorIsShow: boolean = false;

function showError(text: string) {
  if (!errorIsShow) {
    errorIsShow = true;
    Modal.error({
      title: intl.get('systemTips'), // '系统提示',
      content: text,
      afterClose: () => {
        errorIsShow = false;
      },
      okText: intl.get('close'),
    });
  }
}

/** Request */
interface Request {
  /**
   * noloading: 请求过程中不显示loading（默认是显示的）
   * noAutoError: 请求失败后不自动弹出错误（默认会弹出错误）
   */
  get<T>(
    url: string,
    params?: object,
    noloading?: boolean,
    noAutoError?: boolean,
    abortController?: AbortController
  ): Promise<BaseResponse<T>>;
  put<T>(
    url: string,
    params?: object,
    noloading?: boolean,
    noAutoError?: boolean
  ): Promise<BaseResponse<T>>;
  post<T>(
    url: string,
    params?: object,
    noloading?: boolean,
    noAutoError?: boolean,
    abortController?: AbortController
  ): Promise<BaseResponse<T>>;
  delete<T>(
    url: string,
    params?: object,
    noloading?: boolean,
    noAutoError?: boolean,
    abortController?: AbortController
  ): Promise<BaseResponse<T>>;
}

function createRequest(baseURL: string): Request {
  // 创建axios实例
  const service: AxiosInstance = axios.create({
    baseURL: baseURL || '/',
    timeout: 6000, // 超时1分钟
		withCredentials: false, // 垮域请求是否需要使用凭证
	});
	
  // 响应拦截器
  service.interceptors.response.use(
    (response: any) => {
      // server 端访问的code不为0，则统一按失败处理
      if (response.data.code !== 0) {
        return Promise.reject(response);
      }
      return response;
    },
    (error: any) => {
      return Promise.reject(error.response);
    }
  );

  function errorInfo(err: any): BaseResponse<any> {
    if (err.code && err.message) {
      return err;
    }
    const error: BaseResponse<any> = {
      code: -1,
      message: intl.get('requestFailAndRetry'), // `请求失败，请检查网络或稍候重试~`,
    };
    if (err.status) {
      if (err.status === 200) {
        if (err.data) {
          error.code = err.data.code || -1;
          error.message = err.data.message || intl.get('requestFailAndRetry');
          error.data = err.data.data;
        }
      } else {
        switch (err.status) {
          case 400:
            error.message = `请求参数错误~`;
            break;
          case 401:
            error.message = `没有登录或登录已失效~`;
            break;
          case 403:
            error.message = `没有权限~`;
            break;
          case 404:
            error.message = `请求失败，错误代码：${err.status}`;
            break;
          case 405:
            error.message = `请求方法错误~`;
            break;
          case 409:
            error.message = `数据冲突，刷新后重试~`;
            break;
          case 415:
            error.message = `不支持的媒体类型~`;
            break;
          default:
            error.message = `请求失败，请检查网络或稍候重试，错误代码：${err.status}`;
            break;
        }
      }
    }
    return error;
  }

  const doRequest = <T>(
    config: AxiosRequestConfig,
    noloading?: boolean,
    noAutoError?: boolean
  ): Promise<BaseResponse<T>> => {
    return new Promise((resolve, reject) => {
      let closeLoading = () => {};
      if (!noloading) {
        closeLoading = createLoading(config.url || 'request');
      }
      service
        .request<BaseResponse<T>>(config)
        .then((res: any) => {
          closeLoading();
          resolve(res.data);
        })
        .catch((err: Error) => {
          closeLoading();
          // 处理 error
          const e = errorInfo(err || {});
          if (!noAutoError) {
            showError(e.message);
          }
          resolve(e);
        });
    });
  };

  const request: Request = {
    get(
      url: string,
      params?: any,
      noloading?: boolean,
      noAutoError?: boolean,
      abortController?: AbortController
    ) {
      return doRequest(
        { url, method: 'get', params, signal: abortController?.signal },
        noloading,
        noAutoError
      );
    },
    post(
      url: string,
      params?: any,
      noloading?: boolean,
      noAutoError?: boolean,
      abortController?: AbortController
    ) {
      return doRequest(
        { url, method: 'post', data: params, signal: abortController?.signal },
        noloading,
        noAutoError
      );
    },
    put(
      url: string,
      params?: any,
      noloading?: boolean,
      noAutoError?: boolean,
      abortController?: AbortController
    ) {
      return doRequest(
        { url, method: 'put', data: params, signal: abortController?.signal },
        noloading,
        noAutoError
      );
    },
    delete(
      url: string,
      params?: any,
      noloading?: boolean,
      noAutoError?: boolean,
      abortController?: AbortController
    ) {
      return doRequest(
        {
          url,
          method: 'delete',
          data: params,
          signal: abortController?.signal,
        },
        noloading,
        noAutoError
      );
    },
  };
  return request;
}

export default createRequest;
