/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginUserState } from '../types';
import { userApi } from "../api";

export const initialState: LoginUserState = {
  loginResp: {} as userApi.LoginResp,
};

export const loginUserSlice = createSlice({
  name: 'loginUser',
  // 初始值
  initialState,
  reducers: {
		saveLoginResp: (state: LoginUserState, { payload }: PayloadAction<userApi.LoginResp>) => {
      state.loginResp = payload
    },
  },
});

export const {
  saveLoginResp,
} = loginUserSlice.actions;

export default loginUserSlice.reducer;
