import { BaseResponse } from '../types';
import intl from 'react-intl-universal';

export interface Blog {
  id: string;
  title: string;
  subtitle: string;
	image: string;
	author: string;
	authorAvatar: string;
	createTime: string;
	content: string;
}

type PromiseCbkLoadBlogs = (arg: BaseResponse<Blog[]>) => void;
type PromiseCbkBlog = (arg: BaseResponse<Blog>) => void;

let blogs: Blog[] = []

const getLocalBlogs =
	() => (resolve: PromiseCbkLoadBlogs, reject: PromiseCbkLoadBlogs) => {
		blogs = [
			{
				id: '94e957776aba7faab14ade01752dddd9',
				title: intl.get('blog8Title'),
				subtitle: intl.get(`blog8Subtitle`),
				content: intl.get(`blog8Content`),
				image: '/assets/images/blog_8.png',
				author: intl.get(`teamMember2Name`),
				authorAvatar: '/assets/images/shiguangzhai.jpeg',
				createTime: '2024-03',
			},
			{
				id: 'b534b6a48ec6f3f29143f4864b6ffa2e',
				title: intl.get('blog7Title'),
				subtitle: intl.get(`blog7Subtitle`),
				content: intl.get(`blog7Content`),
				image: '/assets/images/blog_7.png',
				author: intl.get(`teamMember2Name`),
				authorAvatar: '/assets/images/shiguangzhai.jpeg',
				createTime: '2024-03',
			},
			{
				id: '1835cfc5917f6b4639e1c8fd21d5fee7',
				title: intl.get('blog6Title'),
				subtitle: intl.get(`blog6Subtitle`),
				content: intl.get(`blog6Content`),
				image: '/assets/images/blog_6.png',
				author: intl.get(`teamMember2Name`),
				authorAvatar: '/assets/images/shiguangzhai.jpeg',
				createTime: '2024-03',
			},
			{
				id: 'd95653ae811d2b4a3ede96d17ca66328',
				title: intl.get('blog5Title'),
				subtitle: intl.get(`blog5Subtitle`),
				content: intl.get(`blog5Content`),
				image: '/assets/images/blog_5.png',
				author: intl.get(`teamMember2Name`),
				authorAvatar: '/assets/images/shiguangzhai.jpeg',
				createTime: '2024-01',
			},
			{
				id: '24505de2967a5e4d100c3af2256ca5b1',
				title: intl.get('blog2Title'),
				subtitle: intl.get(`blog2Subtitle`),
				content: intl.get(`blog2Content`),
				image: '/assets/images/blog_2.png',
				author: intl.get(`teamMember1Name`),
				authorAvatar: '/assets/images/wenrong.jpeg',
				createTime: '2023-07',
			
			},
			{
				id: '9b459515e798b0974baeb181a246f057',
				title: intl.get('blog3Title'),
				subtitle: intl.get(`blog3Subtitle`),
				content: intl.get(`blog3Content`),
				image: '/assets/images/blog_3.png',
				author: intl.get(`teamMember2Name`),
				authorAvatar: '/assets/images/shiguangzhai.jpeg',
				createTime: '2023-08',
			},
			{
				id: 'cd5850ada73dc0aec37c79b1808cea3c',
				title: intl.get('blog4Title'),
				subtitle: intl.get(`blog4Subtitle`),
				content: intl.get(`blog4Content`),
				image: '/assets/images/blog_4.png',
				author: intl.get(`teamMember2Name`),
				authorAvatar: '/assets/images/shiguangzhai.jpeg',
				createTime: '2023-09',
			}
		]
    resolve({
      code: 0,
      data: blogs,
      message: 'succeed',
    });
  };

const getLocalBlog =
	(id: string) => (resolve: PromiseCbkBlog, reject: PromiseCbkBlog) => {
		new Promise<BaseResponse<Blog[]>>(getLocalBlogs())
			.then((res) => {
				resolve({
					code: 0,
					data: blogs.find(item => item.id === id),
					message: 'succeed',
				});
			})
  };

export const loadBlogs = () =>
  new Promise<BaseResponse<Blog[]>>(getLocalBlogs());

export const getBlog = (id: string) =>
  new Promise<BaseResponse<Blog>>(getLocalBlog(id));