import http from '../http';

export const ZhiyaAppVersionType = {
	unknown: 0,
  release: 1,
  test: 2,
}

export const ZhiyaAppPlatform = {
  unknown: 0,
  macos: 1,
  windows: 2,
  ios: 3,
  android: 4,
  linux: 5,
}

export interface AppVersion {
	name: string;
	code: string;
	versionType: number;
	platform: number;
	publishState: number;
	openMod: number;
	upgradeType: number;
	url: string;
	url1: string;
}

export const getLastAppVersion = (versionType: number, platform: number) =>
	http.post<AppVersion>(`/v1/version/latest`, { versionType, platform }, true, true);