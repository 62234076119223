/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LanguageState } from '../types';

export const initialState: LanguageState = {
  language: 'zh_CN',
};

export const languageSlice = createSlice({
  name: 'language',
  // 初始值
  initialState,
  reducers: {
    changeLanguage: (state: LanguageState, { payload }: PayloadAction<string>) => {
      state.language = payload
    },
  },
});

export const {
  changeLanguage,
} = languageSlice.actions;

export default languageSlice.reducer;
