/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MatchMediaState } from '../types';

export const initialState: MatchMediaState = {
  media: 'desktop',
};

export const matchMediaSlice = createSlice({
  name: 'matchMedia',
  // 初始值
  initialState,
  reducers: {
    matchMediaChange: (state: MatchMediaState, { payload }: PayloadAction<'mobile' | 'desktop'>) => {
      state.media = payload
    },
  },
});

export const {
  matchMediaChange,
} = matchMediaSlice.actions;

export default matchMediaSlice.reducer;
