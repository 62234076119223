import { BaseResponse, PromiseCbkString, PromiseCbk } from '../types';

const LanguageStorageKey = 'zhiya-language'

const getLanguageFromToLocalStorage =
  (defaultValue: string) => (resolve: PromiseCbkString, reject: PromiseCbkString) => {
    const language: any = localStorage.getItem(LanguageStorageKey);
    resolve({
      code: 0,
      data: language || defaultValue,
      message: 'succeed',
    });
  };

const saveLanguageToLocalStorage =
  (language: string) =>
  (resolve: PromiseCbk, reject: PromiseCbk) => {
    localStorage.setItem(LanguageStorageKey, language);
    resolve({
      code: 0,
      message: 'succeed',
    });
  };

export const saveLanguage = (language: string) =>
  new Promise<BaseResponse<any>>(saveLanguageToLocalStorage(language));

export const getLanguage = (defaultValue: string) =>
  new Promise<BaseResponse<string>>(getLanguageFromToLocalStorage(defaultValue));